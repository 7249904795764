/*!
 * @license
 * File theme-core.js.
 *
 * Accessibility fixes and setup of the mobile navigation menu.
 */

/* globals Mmenu, themeCoreParams */

/**
 * Main object.
 *
 * @type {Object} themeCore
 */
const themeCore = window.themeCore || {};

/**
 * PHP vars.
 *
 * @type {Object} params
 */
themeCore.params = themeCoreParams;

/**
 * Mobile menu options.
 *
 * @type {Object} mmenu
 *
 * @link https://mmenujs.com
 */
themeCore.mmenu = {
	params: {
		hasLogo: false,
		url: themeCore.params.menu_url ? themeCore.params.menu_url : '',
		title: themeCore.params.menu_title ? themeCore.params.menu_title : '',
		logo: themeCore.params.menu_logo ? themeCore.params.menu_logo : '',
		extensions: [
			'border-full',
			'theme-dark',
			'fullscreen',
		],
		wrappers: ['wordpress', 'bootstrap4']
	}
};

// Topnav.
themeCore.mmenu.params.topNav = {
	use: true,
	position: 'top',
	content: [
		`<a class="mm-navbar__title" href="${themeCore.mmenu.params.url}" rel="home">${themeCore.mmenu.params.title}</a>`,
		'close'
	]
};

// Options.
themeCore.mmenu.opts = {
	extensions: themeCore.mmenu.params.extensions,
	wrappers: themeCore.mmenu.params.wrappers,
	slidingSubmenus: false,
	navbar: false,
	navbars: [themeCore.mmenu.params.topNav]
};

/**
 * Initialize the mobile menu.
 *
 * @type {Function} mmenu.init
 */
themeCore.mmenu.init = () => {
	const menuToggle = document.getElementById( 'menu-toggle' );
	const menuEl     = document.getElementById( 'mobile-navigation' );

	if ( ! document.body.contains( menuToggle ) && ! document.body.contains( menuEl ) ) {
		return;
	}

	if ( 'undefined' === Mmenu || 'function' !== typeof Mmenu || null === Mmenu ) {
		return;
	}

	menuToggle.addEventListener( 'click', ( event ) => {
		event.preventDefault();
	} );

	if ( true === themeCore.mmenu.params.hasLogo ) {
		themeCore.mmenu.params.topNav.content[ 0 ] = `<a class="mm-navbar__title" href="${themeCore.mmenu.params.url}" rel="home"><img src="${themeCore.mmenu.params.logo}" alt="${themeCore.mmenu.params.title}"></a>`;
	}

	// eslint-disable-next-line no-new
	new Mmenu( '#mobile-navigation', themeCore.mmenu.opts );
};

/**
 * Copy link.
 *
 * @type {Function} copyLink
 */
themeCore.copyLink = () => {
	const copyLink = document.querySelector( '.bc-social-icon__copy-link' );

	if ( null === copyLink ) {
		return;
	}

	const copyLinkParent = copyLink.parentNode;
	const linkSrc        = copyLink.getAttribute( 'data-link' );

	copyLink.addEventListener( 'click', () => {
		const inputField          = document.createElement( 'input' );
		inputField.type           = 'text';
		inputField.value          = linkSrc;
		inputField.style.position = 'absolute';
		inputField.style.left     = '-9999px';

		inputField.setAttribute( 'readonly', '' );
		copyLinkParent.appendChild( inputField );
		inputField.select();
		document.execCommand( 'Copy' );
		copyLinkParent.removeChild( inputField );
	} );
};

/**
 * Show/hide menu on scroll up/down.
 *
 * @type {Function} scrollUp
 */
themeCore.scrollUp = () => {
	const body       = document.body;
	const scrollUp   = 'scroll-up';
	const scrollDown = 'scroll-down';
	let lastScroll   = 0;

	window.addEventListener( 'scroll', () => {
		const currentScroll = window.pageYOffset;

		if ( 0 >= currentScroll ) {
			body.classList.remove( scrollUp );

			return;
		}

		if ( currentScroll > lastScroll && ! body.classList.contains( scrollDown ) ) {
			// Down
			body.classList.remove( scrollUp );
			body.classList.add( scrollDown );
		} else if ( currentScroll < lastScroll && body.classList.contains( scrollDown ) ) {
			// Up
			body.classList.remove( scrollDown );
			body.classList.add( scrollUp );
		}
		lastScroll = currentScroll;
	} );
};

/**
 * Initialize object methods.
 *
 * @type {Function} init
 */
themeCore.init = () => {
	themeCore.copyLink();
	themeCore.scrollUp();
};

/**
 * When DOM is ready.
 */
document.addEventListener( 'DOMContentLoaded', () => {
	if ( 'object' !== typeof themeCore && 'undefined' !== typeof themeCore ) {
		return;
	}

	themeCore.mmenu.init();
	themeCore.init();
} );
